<template>
  <div class="home">
    <app-add />
  </div>
</template>
  
<script>
// @ is an alias to /src
import AddFormat from "@/components/AddFormats/AddFormat.vue";

export default {
  name: "AddFormatsView",
  components: {
    appAdd: AddFormat,
  },
  mounted() {
    this.$store.commit("changeComp", 1);
  },
};
</script>
  