<template>
  <div class="home">
    <div class="container">
      <!-- <app-header> </app-header> -->
      <div class="flex-center position-ref full-height">
        <div class="error_page">
          <div class="error_img">
            <img src="../assets/images/404.svg" alt="" />
          </div>
          <div class="error_title">
            {{ errorPage[$i18n.locale].title }}
          </div>
          <div class="error_info">
            {{ errorPage[$i18n.locale].info }}
          </div>
          <a href="/" class="back_button">
            {{ errorPage[$i18n.locale].backHome }}
          </a>
        </div>
      </div>
      <!-- <app-main> </app-main> -->
      <!-- <app-footer v-if="showfooter"> </app-footer> -->
    </div>
  </div>
</template>
  
<script>
// @ is an alias to /src

import Header from "@/components/shared/Header.vue";
import Main from "@/components/main/Main.vue";
import Footer from "@/components/shared/Footer.vue";
export default {
  name: "PageNotFound",
  data() {
    return {
      errorPage: {
        az: {
          title: "Üzr istəyirik, bu səhifəni tapa bilmədik",
          info: "Daxil olmağa çalışdığınız səhifə mövcud deyil və ya köçürülüb. Əsas səhifəmizə qayıtmağa çalışın.",
          backHome: "Ana səhifəyə qayıt",
        },
        en: {
          title: "Oops, we could not find this page",
          info: "The page you are trying to access does not exist or has been moved. Try returning to our home page.",
          backHome: "Go to homepage",
        },
      },
    };
  },
  components: {
    appHeader: Header,
    appMain: Main,
    appFooter: Footer,
    // appFooter: () =>
    //   import(/* webpackChunkName: "footer" */ "@/components/shared/Footer.vue"),
  },
  mounted() {
    this.$store.commit("changeComp", 2);
  },
};
</script>

<style scoped>
html,
body {
  background-color: #fff;
  font-weight: 100;
  height: 100vh;
  margin: 0;
}

.full-height {
  height: 100vh;
  color: #636b6f;
}

.flex-center {
  align-items: center;
  display: flex;
  justify-content: center;
}

.position-ref {
  position: relative;
}
.error_page {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding: 48px 16px 72px 16px;
  max-width: 824px;
  margin: 0 auto;
}
.error_img {
  display: block;
  width: 330px;
  height: 220px;
  margin: 0 auto 32px auto;
}
.error_img img {
  display: block;
  width: 100%;
  height: 100%;
}
.error_title {
  margin-top: 24px;
  font-size: 22px;
  padding: 0 15px 0 15px;
  text-align: center;
  font-weight: bold;
  color: #000000;
}

.error_info {
  margin-top: 16px;
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  color: #000000;
}

.back_button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  padding: 15px 20px;
  margin: 24px auto;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.24px;
  color: #ffffff;
  opacity: 1;
  background-color: #f04b23;
  border-radius: 24px;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  -ms-border-radius: 24px;
  -o-border-radius: 24px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
.back_button:hover {
  background-color: #cb3300 !important;
}
</style>
  