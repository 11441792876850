import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '../i18n'
import VueMeta from 'vue-meta'

Vue.use(VueRouter)
Vue.use(VueMeta)

// export default new VueRouter({
//   mode: 'history',
//   base: process.env.BASE_URL,
//   routes: [
//     {
//       path: '/',
//       redirect: `/${i18n.locale}`
//     },
//     {
//       path: '/:lang',
//       component: {
//         render(c) { return c('router-view') }
//       },
//       children: [
//         {
//           routId: 0,
//           path: '',
//           alias: 'home',
//           name: ' Ana Səhifə',
//           component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue')
//         },
//         {
//           routId: 1,
//           path: 'about-us',
//           name: 'Haqqımızda',
//           // route level code-splitting
//           // this generates a separate chunk (about.[hash].js) for this route
//           // which is lazy-loaded when the route is visited.
//           component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
//         },
//         {
//           routId: 2,
//           path: 'advertisers',
//           name: 'Reklamverənlər',
//           // route level code-splitting
//           // this generates a separate chunk (about.[hash].js) for this route
//           // which is lazy-loaded when the route is visited.
//           component: () => import(/* webpackChunkName: "advertisers" */ '../views/AdvertisersView.vue')
//         },
//         {
//           routId: 3,
//           path: 'publishers',
//           name: 'Yayınçılar',
//           // route level code-splitting
//           // this generates a separate chunk (about.[hash].js) for this route
//           // which is lazy-loaded when the route is visited.
//           component: () => import(/* webpackChunkName: "publishers" */ '../views/PublishersView.vue')
//         },
//         {
//           routId: 4,
//           path: 'faq',
//           name: 'Dəstək',
//           // route level code-splitting
//           // this generates a separate chunk (about.[hash].js) for this route
//           // which is lazy-loaded when the route is visited.
//           component: () => import(/* webpackChunkName: "Faq" */ '../views/FaqView.vue'),
//         },
//         {
//           routId: 5,
//           path: 'faq_Advetisers',
//           name: 'Reklamverən',
//           // route level code-splitting
//           // this generates a separate chunk (about.[hash].js) for this route
//           // which is lazy-loaded when the route is visited.FaqAdvertiserView
//           component: () => import(/* webpackChunkName: "publishers" */ '../views/FaqAdvertiserView.vue')
//         },
//         {
//           routId: 6,
//           path: 'faq_Publishers',
//           name: 'Yayınçı',
//           // route level code-splitting
//           // this generates a separate chunk (about.[hash].js) for this route
//           // which is lazy-loaded when the route is visited. FaqPublisherView
//           component: () => import(/* webpackChunkName: "publishers" */ '../views/FaqPublisherView.vue')
//         },
//         {
//           routId: 6,
//           path: 'ad-formats',
//           name: 'Reklam Formatları',
//           // route level code-splitting
//           // this generates a separate chunk (about.[hash].js) for this route
//           // which is lazy-loaded when the route is visited.
//           component: () => import(/* webpackChunkName: "publishers" */ '../views/FaqFormatsView.vue')
//         },
//         {
//           routId: 7,
//           path: 'faqdocumentation',
//           name: 'Dokumentasiya',
//           // route level code-splitting
//           // this generates a separate chunk (about.[hash].js) for this route
//           // which is lazy-loaded when the route is visited.
//           component: () => import(/* webpackChunkName: "publishers" */ '../views/FaqDocumentationView.vue'),
//           beforeEnter() { location.href = 'https://docs.smartbee.az/' }
//         },
//         {
//           routId: 8,
//           path: 'contact',
//           name: 'Əlaqə',
//           // route level code-splitting
//           // this generates a separate chunk (about.[hash].js) for this route
//           // which is lazy-loaded when the route is visited.
//           component: () => import(/* webpackChunkName: "contact" */ '../views/ContactView.vue')
//         },
//         {
//           // routId: 15,
//           path: ':id',
//           // name: {
//           //   "az": {
//           //     "menu": "Static"
//           //   },
//           //   "en": {
//           //     "menu": "Static"
//           //   }
//           // },
//           // route level code-splitting
//           // this generates a separate chunk (pages.[hash].js) for this route
//           // which is lazy-loaded when the route is visited.
//           component: () => import(/* webpackChunkName: "pages" */ '../views/PagesView.vue'),
//         },
//         {
//           // routId: 15,
//           path: 'ad-formats/:id',
//           // name: {
//           //   "az": {
//           //     "menu": "Static"
//           //   },
//           //   "en": {
//           //     "menu": "Static"
//           //   }
//           // },
//           // route level code-splitting
//           // this generates a separate chunk (pages.[hash].js) for this route
//           // which is lazy-loaded when the route is visited.
//           component: () => import(/* webpackChunkName: "pages" */ '../views/AddFormatsView.vue'),
//         },
//         {
//           // routId: 9,
//           path: "*",
//           // alias: "/hjg",
//           // redirect: '/not',
//           name: 'PageNotFound',
//           // route level code-splitting
//           // this generates a separate chunk (about.[hash].js) for this route
//           // which is lazy-loaded when the route is visited.
//           component: () => import(/* webpackChunkName: "contact" */ '../views/PageNotFoundView.vue')
//         },
//         // {
//         //   path: '*',
//         //   beforeEnter: (to, from, next) => {
//         //     next('/404')
//         //   }
//         // },
//       ]
//     },
//   ]
// });



// Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   redirect: `/${i18n.locale}`
  // },
  {
    path: '/',
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        routId: 0,
        path: '',
        alias: 'home',
        name: ' Ana Səhifə',
        component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue')
      },
      // {
      //   path: '/:lang',
      //   redirect: '/',
      // },
      {
        routId: 1,
        path: 'about-us',
        name: 'Haqqımızda',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
      },
      {
        path: '/:lang/about-us',
        redirect: 'about-us',
      },
      {
        routId: 2,
        path: 'advertisers',
        name: 'Reklamverənlər',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "advertisers" */ '../views/AdvertisersView.vue')
      },
      {
        path: '/:lang/advertisers',
        redirect: 'advertisers',
      },
      {
        routId: 3,
        path: 'publishers',
        name: 'Yayınçılar',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "publishers" */ '../views/PublishersView.vue')
      },
      {
        path: '/:lang/publishers',
        redirect: 'publishers',
      },
      {
        routId: 4,
        path: 'faq',
        name: 'Dəstək',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Faq" */ '../views/FaqView.vue'),
      },
      {
        path: '/:lang/faq',
        redirect: 'faq',
      },
      {
        routId: 5,
        path: 'faq_Advertisers',
        name: 'Reklamverən',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.FaqAdvertiserView
        component: () => import(/* webpackChunkName: "publishers" */ '../views/FaqAdvertiserView.vue')
      },
      {
        path: '/:lang/faq_Advertisers',
        redirect: 'faq_Advertisers',
      },
      {
        routId: 6,
        path: 'faq_Publishers',
        name: 'Yayınçı',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited. FaqPublisherView
        component: () => import(/* webpackChunkName: "publishers" */ '../views/FaqPublisherView.vue')
      },
      {
        path: '/:lang/faq_Publishers',
        redirect: 'faq_Publishers',
      },
      {
        routId: 6,
        path: 'ad-formats',
        name: 'Reklam Formatları',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "publishers" */ '../views/FaqFormatsView.vue')
      },
      {
        path: '/:lang/ad-formats',
        redirect: 'ad-formats',
      },
      {
        routId: 7,
        path: 'faqdocumentation',
        name: 'Dokumentasiya',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "publishers" */ '../views/FaqDocumentationView.vue'),
        beforeEnter() { location.href = 'https://docs.smartbee.az/' }
      },
      {
        routId: 8,
        path: 'contact',
        name: 'Əlaqə',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "contact" */ '../views/ContactView.vue')
      },
      {
        path: '/:lang/contact',
        redirect: 'contact',
      },



      // {
      //   // routId: 15,
      //   path: '/:pages',
      //   // name: {
      //   //   "az": {
      //   //     "menu": "Static"
      //   //   },
      //   //   "en": {
      //   //     "menu": "Static"
      //   //   }
      //   // },
      //   // route level code-splitting
      //   // this generates a separate chunk (pages.[hash].js) for this route
      //   // which is lazy-loaded when the route is visited.
      //   component: () => import(/* webpackChunkName: "pages" */ '../views/PagesView.vue'),
      // },
      // {
      //   path: '/:lang/:pages',
      //   redirect: '/:pages',
      // },


      {
        // routId: 15,
        path: '/non-standard',
        // name: {
        //   "az": {
        //     "menu": "Static"
        //   },
        //   "en": {
        //     "menu": "Static"
        //   }
        // },
        // route level code-splitting
        // this generates a separate chunk (pages.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "pages" */ '../views/PagesView.vue'),
      },
      {
        path: '/:lang/non-standard',
        redirect: '/non-standard',
      },
      {
        // routId: 15,
        path: '/privacy_policy',
        // name: {
        //   "az": {
        //     "menu": "Static"
        //   },
        //   "en": {
        //     "menu": "Static"
        //   }
        // },
        // route level code-splitting
        // this generates a separate chunk (pages.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "pages" */ '../views/PagesView.vue'),
      },
      {
        path: '/:lang/privacy_policy',
        redirect: '/privacy_policy',
      },
      {
        // routId: 15,
        path: '/terms-and-conditions',
        // name: {
        //   "az": {
        //     "menu": "Static"
        //   },
        //   "en": {
        //     "menu": "Static"
        //   }
        // },
        // route level code-splitting
        // this generates a separate chunk (pages.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "pages" */ '../views/PagesView.vue'),
      },
      {
        path: '/:lang/terms-and-conditions',
        redirect: '/terms-and-conditions',
      },
      {
        // routId: 15,
        path: '/return-cancellation',
        // name: {
        //   "az": {
        //     "menu": "Static"
        //   },
        //   "en": {
        //     "menu": "Static"
        //   }
        // },
        // route level code-splitting
        // this generates a separate chunk (pages.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "pages" */ '../views/PagesView.vue'),
      },
      {
        path: '/:lang/return-cancellation',
        redirect: '/return-cancellation',
      },
      {
        // routId: 15,
        path: '/ad-formats/:id',
        // name: {
        //   "az": {
        //     "menu": "Static"
        //   },
        //   "en": {
        //     "menu": "Static"
        //   }
        // },
        // route level code-splitting
        // this generates a separate chunk (pages.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "pages" */ '../views/AddFormatsView.vue'),
      },
      {
        path: '/:lang/ad-formats/:id',
        redirect: '/ad-formats/:id',
      },
      {
        // routId: 9,
        path: "*",
        // redirect: '/not',
        name: 'PageNotFound',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "contact" */ '../views/PageNotFoundView.vue')
      },
      // {
      //   path: '*',
      //   beforeEnter: (to, from, next) => {
      //     next('/404')
      //   }
      // },
    ]
  },
  // {
  //   // routId: 9,
  //   path: "*",
  //   // alias: "/hjg",
  //   // redirect: '/not',
  //   name: 'PageNotFound',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "contact" */ '../views/PageNotFoundView.vue')
  // },
  // {
  //   path: '/az/privacy_policy', // The alternate URL
  //   redirect: '/en/privacy_policy', // Redirect it to the original route
  // },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router



// const router = new VueRouter({
//   mode: 'history',
//   base: process.env.BASE_URL,
//   routes
// })

// export default router
