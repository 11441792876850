<template>
  <div>
    <!-- <h2>pagination - navigation</h2> -->
    <!-- Swiper -->
    <div class="wrapper nonstandart_slider">
      <div class="slider-area">
        <div class="order-btn">
          <a class="order-link" href="#">
            Sifariş
            <img
              class="order-icon"
              src="@/assets/images/nonstandard/right-arrow.png"
            />
          </a>
        </div>
        <div class="swiper_logo">
          <img src="@/assets/images/nonstandard/smart_bee_main_logo.png" />
        </div>
        <div class="kontakt">
          <swiper
            :slides-per-view="5"
            :space-between="0"
            :loop="true"
            :effect="'coverflow'"
            :grabCursor="true"
            :coverflowEffect="{
              rotate: 0,
              stretch: 0,
              depth: 500,
              modifier: 2,
              slideShadows: false,
            }"
            :speed="1800"
            :autoplay="{
              delay: 1100,
              disableOnInteraction: false,
            }"
            :centeredSlides="true"
            :pagination="false"
            :navigation="true"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
            :breakpoints="{
              '0': {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              '560': {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              '768': {
                slidesPerView: 4,
                spaceBetween: 40,
              },
              '1024': {
                slidesPerView: 4,
                spaceBetween: 40,
              },
            }"
          >
            <swiper-slide data-aos="fade-up" data-aos-duration="1000">
              <div class="slider-image">
                <img src="@/assets/images/nonstandard/img1.jpg" alt="" />
                <input
                  type="text"
                  value="Satılır 5 otaqlı ev / villa 250 m², Buzovna q."
                  class="caption_sld"
                />
                <input type="text" value="70000 azn" class="caption_sld_2" />
              </div>
            </swiper-slide>
            <swiper-slide data-aos="fade-up" data-aos-duration="1000">
              <div class="slider-image">
                <img src="@/assets/images/nonstandard/img2.jpg" alt="" />
                <input
                  type="text"
                  value="Satılır 3 otaqlı köhnə tikili 65 m², Bayıl q."
                  class="caption_sld"
                />
                <input type="text" value="90000 azn" class="caption_sld_2" />
              </div>
            </swiper-slide>
            <swiper-slide data-aos="fade-up" data-aos-duration="1000">
              <div class="slider-image">
                <img src="@/assets/images/nonstandard/img3.jpg" alt="" />
                <input
                  type="text"
                  value="Satılır 3 otaqlı yeni tikili 126 m², Nəsimi r."
                  class="caption_sld"
                />
                <input type="text" value="300000 AZN" class="caption_sld_2" />
              </div>
            </swiper-slide>
            <swiper-slide data-aos="fade-up" data-aos-duration="1000">
              <div class="slider-image">
                <img src="@/assets/images/nonstandard/img3.jpg" alt="" />
                <input
                  type="text"
                  value="Satılır 2 otaqlı yeni tikili 65 m², 7-ci mikrorayon q."
                  class="caption_sld"
                />
                <input type="text" value="150000 azn" class="caption_sld_2" />
              </div>
            </swiper-slide>
            <swiper-slide data-aos="fade-up" data-aos-duration="1000">
              <div class="slider-image">
                <img src="@/assets/images/nonstandard/img3.jpg" alt="" />
                <input
                  type="text"
                  value="Satılır 3 otaqlı yeni tikili 126 m², Nəsimi r."
                  class="caption_sld"
                />
                <input type="text" value="110000 azn" class="caption_sld_2" />
              </div>
            </swiper-slide>
          </swiper>
        </div>
        <div class="capt_result">
          <input type="hidden" id="capVal" value="" />
          <div class="cat_reslut_inner"></div>
        </div>
        <div class="capt_result_2">
          <input type="hidden" id="capVal2" value="" />
          <div class="cat_reslut_inner_2"></div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import { Autoplay, Navigation, EffectCoverflow, Pagination } from "swiper";

import { SwiperCore, Swiper, SwiperSlide } from "swiper-vue2";

// Import Swiper styles
import "swiper/swiper-bundle.css";

SwiperCore.use([Autoplay, EffectCoverflow, Navigation, Pagination]);

export default {
  name: "Nonstandart",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {};
  },
  // props: ["formatsApi"],
  methods: {
    onSwiper(swiper) {
      var swiperSlides = document.querySelectorAll(
        ".swiper-slide.swiper-slide-active"
      );
      swiperSlides.forEach(function (slide) {
        var this_val = slide.querySelector(".caption_sld").value;
        document.getElementById("capVal").value = this_val;
        document.querySelector(".cat_reslut_inner").innerHTML = this_val;

        var this_val_new = slide.querySelector(".caption_sld_2").value;
        document.getElementById("capVal2").value = this_val_new;
        document.querySelector(".cat_reslut_inner_2").innerHTML = this_val_new;
      });
    },
    onSlideChange() {
      var swiperSlides = document.querySelectorAll(
        ".swiper-slide.swiper-slide-active"
      );
      swiperSlides.forEach(function (slide) {
        var this_val = slide.querySelector(".caption_sld").value;
        document.getElementById("capVal").value = this_val;
        document.querySelector(".cat_reslut_inner").innerHTML = this_val;

        var this_val_new = slide.querySelector(".caption_sld_2").value;
        document.getElementById("capVal2").value = this_val_new;
        document.querySelector(".cat_reslut_inner_2").innerHTML = this_val_new;
      });
    },
  },
  mounted() {},
};
</script>
  
<style >
.cat_reslut_inner {
  text-align: left;
}
.wrapper {
  max-width: 800px;
  height: 181px;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0px;
}

.slider-area {
  width: 800px;
  height: 121px;
  background: red;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

#close_catfish {
  display: flex;
  width: 20px;
  align-items: center;
  height: 20px;
  background: black;
  z-index: 100;
  right: 9px;
  top: 7px;
  position: absolute;
  cursor: pointer;
  justify-content: center;
}

.swiper_logo {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100px;
  height: 100px;
  left: 0;
  margin: 10px auto 10px 20px;
}

.swiper_logo img {
  display: block;
  width: 100%;
  height: 100%;
}

#close-btn {
  color: white;
  font-weight: bold;
  font-size: 13px;
}

.slider-image {
  cursor: pointer;
}

.capt_result {
  display: inline-block;
  width: 100%;
  max-width: 230px;
  font-size: 24px;
  position: absolute;
  z-index: 10;
  bottom: 5px;
  left: 41%;
  transform: translateX(-50%);
  max-height: 32px;
  overflow: hidden;
}

.capt_result_2 {
  display: inline-block;
  width: 100%;
  max-width: 130px;
  font-size: 24px;
  position: absolute;
  z-index: 10;
  bottom: 8px;
  left: 75%;
  transform: translateX(-80%);
  background: #ffffff;
  border-radius: 5px;
}

.cat_reslut_inner {
  color: #fff;
  font-weight: normal;
  padding-right: 10px;
  font-size: 14px;
  word-break: break-all;
  line-height: 16px;
}

.cat_reslut_inner_2 {
  color: black;
  font-weight: 500;
  padding: 3px 10px;
  font-size: 16px;
  word-break: break-all;
  text-transform: uppercase;
}

.order-btn {
  width: 120px;
  align-items: center;
  height: 30px;
  background: #ffffff;
  border-radius: 10px;
  position: absolute;
  right: 18px;
  top: 64%;
  transform: translateY(-64%);
  z-index: 100;
}

.order-link {
  display: flex;
  font-size: 16px;
  color: black;
  text-decoration: none;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.order-icon {
  width: 24px;
  margin-left: 6px;
}

.swiper-pagination-bullet {
  border: 1px solid #1da1f2;
}

.swiper-pagination-bullet-active {
  background: #1da1f2;
}

img:not([draggable]),
embed,
object,
video {
  max-width: 100%;
  height: auto;
}

.kontakt {
  display: block;
  width: 700px;
  margin: 0 auto;
  position: absolute;
  z-index: 10;
  top: -68px;
  left: 50%;
  transform: translateX(-50%);
}

.slider-image {
  background-color: rgb(177, 155, 155);
  width: 100%;
  height: 135px;
}

.slider-image img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slider-image .caption_sld {
  display: none;
}

.slider-image .caption_sld_2 {
  display: none;
}

.swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.swiper-container-no-flexbox .swiper-slide {
  float: left;
}

.swiper-container-vertical > .swiper-wrapper {
  -webkit-box-orient: vertical;
  -ms-flex-direction: column;
  flex-direction: column;
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  box-sizing: content-box;
}

.swiper-container-android .swiper-slide,
.swiper-wrapper {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.swiper-container-multirow > .swiper-wrapper {
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.swiper-container-free-mode > .swiper-wrapper {
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  margin: 0 auto;
}

.swiper-slide {
  -webkit-flex-shrink: 0;
  -ms-flex: 0 0 auto;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
}

.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
  height: auto;
}

.swiper-container-autoheight .swiper-wrapper {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-transition-property: -webkit-transform, height;
  -webkit-transition-property: height, -webkit-transform;
  transition-property: height, -webkit-transform;
  transition-property: transform, height;
  transition-property: transform, height, -webkit-transform;
}

.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000;
}

.swiper-wp8-horizontal {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.swiper-wp8-vertical {
  -ms-touch-action: pan-x;
  touch-action: pan-x;
}

.swiper-button-next,
.swiper-button-prev {
  position: absolute;
  top: 50%;
  width: 27px;
  height: 44px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  background-size: 27px 44px;
  background-position: center;
  background-repeat: no-repeat;
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  /*background-image: url("data:img/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");*/
  left: 10px;
  right: auto;
}

.swiper-button-prev.swiper-button-black,
.swiper-container-rtl .swiper-button-next.swiper-button-black {
  background-image: url("data:img/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-prev.swiper-button-white,
.swiper-container-rtl .swiper-button-next.swiper-button-white {
  background-image: url("data:img/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background-image: url("data:img/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  right: 10px;
  left: auto;
}

.swiper-button-next.swiper-button-black,
.swiper-container-rtl .swiper-button-prev.swiper-button-black {
  background-image: url("data:img/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-next.swiper-button-white,
.swiper-container-rtl .swiper-button-prev.swiper-button-white {
  background-image: url("data:img/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}

.swiper-pagination {
  position: absolute;
  text-align: center;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 10;
}

.swiper-pagination.swiper-pagination-hidden {
  opacity: 0;
}

.swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 10px;
  left: 0;
  width: 100%;
}

.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  background: #000;
  opacity: 0.2;
}

button.swiper-pagination-bullet {
  border: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}

.swiper-pagination-white .swiper-pagination-bullet {
  background: #fff;
}

.swiper-pagination-bullet-active {
  opacity: 1;
  background: #007aff;
}

.swiper-pagination-white .swiper-pagination-bullet-active {
  background: #fff;
}

.swiper-pagination-black .swiper-pagination-bullet-active {
  background: #000;
}

.swiper-container-vertical > .swiper-pagination-bullets {
  right: 10px;
  top: 50%;
  -webkit-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
}

.swiper-container-vertical
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 5px 0;
  display: block;
}

.swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 5px;
}

.swiper-pagination-progress {
  background: rgba(0, 0, 0, 0.25);
  position: absolute;
}

.swiper-pagination-progress .swiper-pagination-progressbar {
  background: #007aff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: left top;
  transform-origin: left top;
}

.swiper-container-rtl
  .swiper-pagination-progress
  .swiper-pagination-progressbar {
  -webkit-transform-origin: right top;
  transform-origin: right top;
}

.swiper-container-horizontal > .swiper-pagination-progress {
  width: 100%;
  height: 4px;
  left: 0;
  top: 0;
}

.swiper-container-vertical > .swiper-pagination-progress {
  width: 4px;
  height: 100%;
  left: 0;
  top: 0;
}

.swiper-pagination-progress.swiper-pagination-white {
  background: rgba(255, 255, 255, 0.5);
}

.swiper-pagination-progress.swiper-pagination-white
  .swiper-pagination-progressbar {
  background: #fff;
}

.swiper-pagination-progress.swiper-pagination-black
  .swiper-pagination-progressbar {
  background: #000;
}

.swiper-container-3d {
  -webkit-perspective: 1200px;
  -o-perspective: 1200px;
  perspective: 1200px;
}

.swiper-container-3d .swiper-cube-shadow,
.swiper-container-3d .swiper-slide,
.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-wrapper {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
}

.swiper-container-3d .swiper-slide-shadow-left {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(0, 0, 0, 0.5)),
    to(rgba(0, 0, 0, 0))
  );
  background-image: -webkit-linear-gradient(
    right,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  background-image: linear-gradient(
    to left,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
}

.swiper-container-3d .swiper-slide-shadow-right {
  background-image: -webkit-gradient(
    linear,
    right top,
    left top,
    from(rgba(0, 0, 0, 0.5)),
    to(rgba(0, 0, 0, 0))
  );
  background-image: -webkit-linear-gradient(
    left,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
}

.swiper-container-3d .swiper-slide-shadow-top {
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 0.5)),
    to(rgba(0, 0, 0, 0))
  );
  background-image: -webkit-linear-gradient(
    bottom,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
}

.swiper-container-3d .swiper-slide-shadow-bottom {
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(rgba(0, 0, 0, 0.5)),
    to(rgba(0, 0, 0, 0))
  );
  background-image: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
}

.swiper-container-coverflow .swiper-wrapper,
.swiper-container-flip .swiper-wrapper {
  -ms-perspective: 1200px;
}

.swiper-container-cube,
.swiper-container-flip {
  overflow: visible;
}

.swiper-container-cube .swiper-slide,
.swiper-container-flip .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1;
}

.swiper-container-cube .swiper-slide .swiper-slide,
.swiper-container-flip .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-active .swiper-slide-active,
.swiper-container-flip .swiper-slide-active,
.swiper-container-flip .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-container-cube .swiper-slide-shadow-bottom,
.swiper-container-cube .swiper-slide-shadow-left,
.swiper-container-cube .swiper-slide-shadow-right,
.swiper-container-cube .swiper-slide-shadow-top,
.swiper-container-flip .swiper-slide-shadow-bottom,
.swiper-container-flip .swiper-slide-shadow-left,
.swiper-container-flip .swiper-slide-shadow-right,
.swiper-container-flip .swiper-slide-shadow-top {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.swiper-container-cube .swiper-slide {
  visibility: hidden;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  width: 100%;
  height: 100%;
}

.swiper-container-cube.swiper-container-rtl .swiper-slide {
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0;
}

.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-next,
.swiper-container-cube .swiper-slide-next + .swiper-slide,
.swiper-container-cube .swiper-slide-prev {
  pointer-events: auto;
  visibility: visible;
}

.swiper-container-cube .swiper-cube-shadow {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.6;
  -webkit-filter: blur(50px);
  filter: blur(50px);
  z-index: 0;
}

.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.swiper-container-fade .swiper-slide {
  pointer-events: none;
  -webkit-transition-property: opacity;
  transition-property: opacity;
}

.swiper-container-fade .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-zoom-container {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.swiper-zoom-container > canvas,
.swiper-zoom-container > img,
.swiper-zoom-container > svg {
  max-width: 100%;
  max-height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.swiper-scrollbar {
  border-radius: 10px;
  position: relative;
  -ms-touch-action: none;
  background: rgba(0, 0, 0, 0.1);
}

.swiper-container-horizontal > .swiper-scrollbar {
  position: absolute;
  left: 1%;
  bottom: 3px;
  z-index: 50;
  height: 5px;
  width: 98%;
}

.swiper-container-vertical > .swiper-scrollbar {
  position: absolute;
  right: 3px;
  top: 1%;
  z-index: 50;
  width: 5px;
  height: 98%;
}

.swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  left: 0;
  top: 0;
}

.swiper-scrollbar-cursor-drag {
  cursor: move;
}

.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  animation: swiper-preloader-spin 1s steps(12, end) infinite;
}

.swiper-lazy-preloader:after {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  background-image: url("data:img/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-position: 50%;
  background-size: 100%;
  background-repeat: no-repeat;
}

.swiper-lazy-preloader-white:after {
  background-image: url("data:img/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}

@-webkit-keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.swiper-container.one {
  padding-top: 40px;
  text-align: center;
}

.swiper-container.one .swiper-slide {
  padding: 0 43px;
}

.swiper-container {
  width: 100%;
  padding-bottom: 60px;
}

.swiper-slide img {
  display: block;
  margin: auto;
  width: 100%;
}

.swiper-slide img {
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
}

.swiper-slide .slider-image .preview-icon {
  z-index: -1;
  width: calc(100% - 30px);
}

.swiper-slide.swiper-slide-active .slider-image:hover .preview-icon {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  z-index: 1;
}

.swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 0;
}

.swiper-pagination-bullet {
  background: transparent none repeat scroll 0 0;
  border: 1px solid #ee0f6f;
  border-radius: 10px;
  display: inline-block;
  height: 10px;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  width: 26px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.swiper-pagination-bullet-active {
  background: rgba(0, 0, 0, 0) -webkit-linear-gradient(
      left,
      #ee0f6f 0%,
      #f89482 100%
    ) repeat scroll 0 0;
  background: rgba(0, 0, 0, 0)
    linear-gradient(to right, #ee0f6f 0%, #f89482 100%) repeat scroll 0 0;
  border: medium none;
  height: 12px;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  width: 12px;
}

.nonstandart_slider .swiper-slide:not(.swiper-slide-active) {
  filter: blur(3px) !important;
}

/*swiper-arows-seting*/

:root {
  --swiper-theme-color: transparent !important;
}

.nonstandart_slider .swiper-button-next,
.nonstandart_slider .swiper-container-rtl .swiper-button-prev {
  right: 130px;
  width: 28px;
  height: 28px;
  border-radius: 5px;
  background-image: url("@/assets/images/nonstandard/right-arow.svg") !important;
  background-color: white;
  padding: 3px;
  background-size: 60% 60%;
  background-position: center center;
}

.nonstandart_slider .swiper-button-prev,
.nonstandart_slider .swiper-container-rtl .swiper-button-next {
  left: 130px;
  width: 28px;
  height: 28px;
  background-image: url("@/assets/images/nonstandard/left-arow.svg") !important;
  background-color: white;
  border-radius: 5px;
  background-size: 60% 60%;
  background-position: center center;
}

/*swiper-arows-seting*/
</style>