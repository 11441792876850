import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueMeta from 'vue-meta'
import store from './store'
import i18n from "./i18n"
import AOS from 'aos'
import 'aos/dist/aos.css'
// export const eventBus = new Vue()

Vue.config.productionTip = false
Vue.use(VueMeta, {
  keyName: 'head'
})

// use beforeEach route guard to set the language

router.beforeEach((to, from, next) => {


  // use the language from the routing param or default language
  let language = to.params.lang;
  // if(language == "") {
  //   console.log("langgggsggsgdsg");
  //   // this.$router.push({
  //   //   params: { lang: "en" },
  //   // });
  // }
  // language = 'az'

  if(language == "az") {
    language = "az"
    // console.log("default languageeeeeeeeeeeeeee" + language)
  } else if(language == "en") {
    language = "en"
    // console.log("default languageeeeeeeeeeeeeee" + language)
  }
  else {
    language = "az"
    // setTimeout(() => {
    //   // next(' /error')
    // }, 100)
  }
  // set the current language for i18n.

  i18n.locale = language
  next()

  // document.title = to.meta.title

});

new Vue({
  router,
  i18n,
  store,
  render: h => h(App),
  mounted() {
    AOS.init()
  },
}).$mount('#app')
