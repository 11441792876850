<template>
  <div class="container">
    <router-view />
  </div>
</template>
<script>
// import Home from "@/components/main/Home.vue";
export default {
  name: "Main",
  // updated() {
  //   this.$store.commit("showUnder", true);
  //   console.log("main motdd");
  // },
};
</script>