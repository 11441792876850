<template>
  <footer class="footer">
    <div class="centered">
      <div class="footer-wrap">
        <div class="ft-wrap-left">
          <div class="footer-logo"><a href="/"></a></div>

          <div class="footer-text">
            <p>
              {{ footerText.menu[$i18n.locale].footer_text }}
            </p>
          </div>

          <div class="footer-contact">
            <ul>
              <li class="phone phone_2">
                <a href="tel:+994507910786"> +994507910786 </a>
              </li>
              <li class="phone">
                <a href="tel:+994703306355"> +994703306355</a>
              </li>
              <li class="mail">
                <a target="_blank" href="mailto:info@smartbee.az">
                  info@smartbee.az
                </a>
              </li>
              <li class="fb">
                <a target="_blank" href="https://www.facebook.com/smartbee.az"
                  >Facebook</a
                >
              </li>
              <li class="ins">
                <a target="_blank" href="https://www.instagram.com/smartbeeaz/"
                  >Instagram</a
                >
              </li>
              <li class="lnk">
                <a
                  target="_blank"
                  href="https://www.linkedin.com/company/smartbee-az/mycompany/"
                  >Linkedin</a
                >
              </li>
            </ul>
            <div class="footer_menu">
              <router-link
                tag="a"
                target="_blank"
                class="nav-item"
                exact
                active-class="active"
                v-for="route in staticMenu"
                :key="route.id"
                :to="`/${$i18n.locale}/` + route.slug"
              >
                <p style="color: white">
                  {{ route.title[$i18n.locale] }}
                </p>
              </router-link>

              <!-- <a href="https://smartbee.az/az/privacy_policy">
                <p style="color: white">Privacy Policy</p>
              </a>
              <a href="https://smartbee.az/az/terms-and-conditions">
                <p style="color: white">Terms and conditions</p>
              </a>
              <a href="https://smartbee.az/az/return-cancellation">
                <p style="color: white">Return Cancellation</p>
              </a> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
// @ is an alias to /src
import axios from "axios";
export default {
  name: "Footer",
  data() {
    return {
      // routes: this.$router.options.routes,
      // routName: this.$router.options.routes[1].children[10],
      staticMenu: [],
      footerText: [],
    };
  },
  mounted() {
    axios
      .post("https://api2.smartbee.az/api/menu?token=abc")
      .then((response) => {
        this.staticMenu = response.data.data.pages;
        this.footerText = response.data.data;
      })
      .catch((error) => {
        console.log(error);
        this.errored = true;
      })
      .finally(() => (this.loading = false));
  },
  methods: {
    // reloadPage() {
    //   // var staticUrl = window.location.href.toString().split("/")[5];
    //   // this.$store.commit("showCurrentStatic", staticUrl);
    //   // console.log("reloaddd");
    //   // console.log(staticUrl);
    //   setTimeout(() => {
    //     window.location.reload();
    //   }, 100);
    // },
  },
};
</script>
<style scoped>
.footer-contact {
  flex-wrap: wrap !important;
}
.footer_menu a {
  margin-right: 10px;
}
.footer_menu a:hover {
  text-decoration: underline;
  color: #fff;
}
.footer_menu {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - 200px);
  margin: 0px 0px;
  justify-content: flex-start;
}
.footer-contact ul {
  display: block;
  width: 100%;
}
@media screen and (max-width: 700px) {
  .footer_menu {
    width: 100%;
  }
  .footer_menu a {
    margin-bottom: 5px;
  }
  .footer-contact ul li {
    width: 100%;
  }
}
</style>
  