<template>
  <div class="page">
    <app-special v-if="showcomponent == 0"> </app-special>
    <app-other v-else-if="showcomponent == 1"> </app-other>
    <app-error v-else-if="showcomponent == 2"> </app-error>
  </div>
</template>
<script>
import Other from "@/views/AddFormatsView.vue";
import Error from "@/views/PageNotFoundView.vue";
import Special from "@/views/MainPagesView.vue";
export default {
  metaInfo: {
    // htmlAttrs: {
    //   lang: this.$i18n.locale,
    // },
    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      { name: "author", content: "smartbee.az" },
      { name: "copyright", content: "https://smartbee.az/az" },
    ],
  },
  components: {
    appSpecial: Special,
    appOther: Other,
    appError: Error,
  },
  computed: {
    showcomponent() {
      return this.$store.getters.getChangeVal;
    },
  },
};
</script>

<style>
#app {
  width: 100%;
}
</style>
  
