<template>
  <header class="header" id="header">
    <div class="centered-a">
      <!-- <button type="button" @click="sendParent">Parent gonder</button> -->
      <div class="header-wrap">
        <div class="mobile-menu" @click="mobileMenu"></div>
        <div class="logo" @click="logoClick">
          <!-- <a href="/"></a> -->
          <router-link tag="a" :to="`/`"> </router-link>
        </div>
        <div class="header-right">
          <div class="top-menu">
            <ul>
              <li class="nav-item" @click="logoClick">
                <router-link
                  class="nav-link"
                  tag="a"
                  exact
                  active-class="active"
                  :to="`/`"
                >
                  {{ $t("header.homepage") }}
                </router-link>
              </li>
              <li class="nav-item" @click="logoClick">
                <router-link
                  class="nav-link"
                  tag="a"
                  exact
                  active-class="active"
                  :to="`/about-us`"
                >
                  {{ $t("header.about") }}
                </router-link>
              </li>
              <li class="nav-item" @click="logoClick">
                <router-link
                  class="nav-link"
                  tag="a"
                  exact
                  active-class="active"
                  :to="`/advertisers`"
                >
                  {{ $t("header.advertisers") }}
                </router-link>
              </li>
              <li class="nav-item" @click="logoClick">
                <router-link
                  class="nav-link"
                  tag="a"
                  exact
                  active-class="active"
                  :to="`/publishers`"
                >
                  {{ $t("header.publishers") }}
                </router-link>
              </li>
              <li class="nav-item has-sub" @click="logoClick">
                <a class="nav-link" tag="a" exact active-class="">
                  {{ $t("header.faq") }}
                </a>
                <div class="sub-menu">
                  <ul>
                    <li class="nav-item" @click="logoClick">
                      <router-link
                        class="nav-link"
                        tag="a"
                        exact
                        active-class="active"
                        :to="`/faq_Advertisers`"
                      >
                        {{ $t("header.faqadvertiser") }}
                      </router-link>
                    </li>
                    <li class="nav-item" @click="logoClick">
                      <router-link
                        class="nav-link"
                        tag="a"
                        exact
                        active-class="active"
                        :to="`/faq_Publishers`"
                      >
                        {{ $t("header.faqpublisher") }}
                      </router-link>
                    </li>
                    <li class="nav-item" @click="logoClick">
                      <router-link
                        class="nav-link"
                        tag="a"
                        exact
                        active-class="active"
                        :to="`/ad-formats`"
                      >
                        {{ $t("header.faqformats") }}
                      </router-link>
                    </li>
                    <li class="nav-item" @click="logoClick">
                      <router-link
                        class="nav-link"
                        tag="a"
                        exact
                        active-class="active"
                        :to="`/faqdocumentation`"
                      >
                        {{ $t("header.faqdocumentation") }}
                      </router-link>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="nav-item" @click="logoClick">
                <router-link
                  class="nav-link"
                  tag="a"
                  exact
                  active-class="active"
                  :to="`/contact`"
                >
                  {{ $t("header.contact") }}
                </router-link>
              </li>
            </ul>
          </div>
          <div class="login-button">
            <a
              :href="'https://panel.smartbee.az/login/' + $i18n.locale"
              target="_blank"
            >
              {{ loginLang[$i18n.locale] }}
            </a>
          </div>
          <div class="mobile-login">
            <a href="https://panel.smartbee.az/login" target="_blank"></a>
          </div>
          <div class="language" :class="{ hv: isActive }">
            <span @click="langOpen">
              {{ $t("lang.name") }}
            </span>
            <ul>
              <li :class="$i18n.locale">
                <a href="#" class="en" @click.prevent="setLocale('en')">
                  Eng
                </a>
                <a href="#" class="az" @click.prevent="setLocale('az')">
                  Aze
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- Mobile menu start -->
    <div class="mobile-fixed" @click="closeMenu">
      <div class="menu-panel" @click="stopMenu">
        <div class="menu-panel-close" @click="closeMenu"><i></i></div>
        <nav class="mob-menu">
          <ul>
            <router-link
              tag="li"
              class="nav-item"
              exact
              active-class="active"
              :to="`/`"
            >
              <a class="nav-link" @click="closeMenu">
                {{ $t("header.homepage") }}
              </a>
            </router-link>
            <router-link
              tag="li"
              class="nav-item"
              exact
              active-class="active"
              :to="`/about-us`"
            >
              <a class="nav-link" @click="closeMenu">
                {{ $t("header.about") }}
              </a>
            </router-link>
            <router-link
              tag="li"
              class="nav-item"
              exact
              active-class="active"
              :to="`/advertisers`"
            >
              <a class="nav-link" @click="closeMenu">
                {{ $t("header.advertisers") }}
              </a>
            </router-link>
            <router-link
              tag="li"
              class="nav-item"
              exact
              active-class="active"
              :to="`/publishers`"
            >
              <a class="nav-link" @click="closeMenu">
                {{ $t("header.publishers") }}
              </a>
            </router-link>
            <li
              tag="li"
              class="nav-item has-mob-sub mobileDrop"
              :class="{ opnd: mobileDrop }"
              exact
              active-class="active"
              :to="``"
              @click="mobileDropMenu"
            >
              <a class="nav-link">
                {{ $t("header.faq") }}
              </a>
              <i class="mobil-down" @click="mobileDropMenu"></i>
              <div class="sub-mob-menu">
                <ul>
                  <router-link
                    tag="li"
                    class=""
                    exact
                    active-class="active"
                    :to="`/faq_Advertisers`"
                  >
                    <a class="" @click="closeMenu">
                      {{ $t("header.faqadvertiser") }}
                    </a>
                  </router-link>
                  <router-link
                    tag="li"
                    class=""
                    exact
                    active-class="active"
                    :to="`/faq_Publishers`"
                  >
                    <a class="" @click="closeMenu">
                      {{ $t("header.faqpublisher") }}
                    </a>
                  </router-link>
                  <router-link
                    tag="li"
                    class=""
                    exact
                    active-class="active"
                    :to="`/ad-formats`"
                  >
                    <a class="" @click="closeMenu">
                      {{ $t("header.faqformats") }}
                    </a>
                  </router-link>
                  <router-link
                    tag="li"
                    class=""
                    exact
                    active-class="active"
                    :to="`/faqdocumentation`"
                  >
                    <a class="" @click="closeMenu">
                      {{ $t("header.faqdocumentation") }}
                    </a>
                  </router-link>
                </ul>
              </div>
            </li>
            <router-link
              tag="li"
              class="nav-item"
              exact
              active-class="active"
              :to="`/contact`"
            >
              <a class="nav-link" @click="closeMenu">
                {{ $t("header.contact") }}
              </a>
            </router-link>
          </ul>
          <!-- <ul>
            <router-link
              tag="li"
              class=""
              :class="[
                mroute.routId >= 4 && mroute.routId <= 7 ? 'has-mob-sub' : '',
                { opnd: mobileDrop },
              ]"
              exact
              active-class="active"
              v-for="mroute in routes[1].children"
              :key="mroute.path"
              :to="`/` + mroute.path"
              v-if="mroute.routId < 5 || mroute.routId > 7"
            >
              <a class="" @click="closeMenu">
                {{ mroute.name }}
              </a>
              <i
                class="mobil-down"
                v-if="mroute.routId == 4"
                @click="mobileDropMenu"
              ></i>
              <div
                class="sub-mob-menu"
                v-if="mroute.routId >= 4 && mroute.routId <= 7"
              >
                <ul>
                  <router-link
                    tag="li"
                    class=""
                    v-for="mroute in routes[1].children"
                    :key="mroute.path"
                    :to="`/` + mroute.path"
                    v-if="mroute.routId >= 5 && mroute.routId <= 7"
                  >
                    <a class="" @click="closeMenu">{{ mroute.name }}</a>
                  </router-link>
                </ul>
              </div>
            </router-link>
          </ul> -->
        </nav>
      </div>
    </div>
    <!-- Mobile menu end -->
  </header>
</template>
<script>
export default {
  name: "Header",
  data() {
    return {
      toLink: "",
      nevMylng: "az",
      routes: this.$router.options.routes,
      // subMenu: "has-sub",
      // subMenuMobile: false,
      yes: "",
      dropOpen: "",
      hover: false,
      isShow: false,
      havDrop: false,
      mobileDrop: false,
      isActive: false,
      loginLang: {
        az: "Daxil ol",
        en: "Login",
      },
    };
  },

  computed: {
    reloadPage() {
      return this.$store.getters.getReloadVal;
    },
    newCurrentLang() {
      return this.$store.getters.currentLang;
    },
  },
  // computed: {
  //   currentRouteName() {
  //     return this.$route.name;
  //   },
  // },
  updated() {
    var logUrl = window.location.href.split("/");

    if (localStorage.nevMylng) {
      this.nevMylng = localStorage.nevMylng;
    } else {
      if (
        logUrl[3] == "privacy_policy" ||
        logUrl[3] == "terms-and-conditions" ||
        logUrl[3] == "return-cancellation" ||
        logUrl[4] == "privacy_policy" ||
        logUrl[4] == "terms-and-conditions" ||
        logUrl[4] == "return-cancellation"
      ) {
        this.nevMylng = "en";
        localStorage.nevMylng = this.nevMylng;
        // console.log(logUrl[3]);
        // console.log("dfhfdhdfhdfhdh");
      } else {
        this.nevMylng = "az";
        // console.log("ttttttttttttttt");
      }
      localStorage.nevMylng = this.nevMylng;
    }

    // console.log(logUrl[3]);

    var nwLg = localStorage.nevMylng;
    this.$i18n.locale = nwLg;

    var staticLang = localStorage.nevMylng;
    this.$store.commit("newCurrentLang", staticLang);
  },
  created() {
    var logUrl = window.location.href.split("/");

    if (localStorage.nevMylng) {
      this.nevMylng = localStorage.nevMylng;
    } else {
      if (
        logUrl[3] == "privacy_policy" ||
        logUrl[3] == "terms-and-conditions" ||
        logUrl[3] == "return-cancellation" ||
        logUrl[4] == "privacy_policy" ||
        logUrl[4] == "terms-and-conditions" ||
        logUrl[4] == "return-cancellation"
      ) {
        this.nevMylng = "en";
        localStorage.nevMylng = this.nevMylng;
        // console.log(logUrl[3]);
        // console.log("dfhfdhdfhdfhdh");
      } else {
        this.nevMylng = "az";
        // console.log("ttttttttttttttt");
      }
      localStorage.nevMylng = this.nevMylng;
    }

    // console.log(logUrl[3]);

    var nwLg = localStorage.nevMylng;
    this.$i18n.locale = nwLg;

    var staticLang = localStorage.nevMylng;
    this.$store.commit("newCurrentLang", staticLang);
  },

  mounted() {
    this.nevMylng = localStorage.nevMylng;
    var nwLg = this.nevMylng;

    this.$i18n.locale = nwLg;
  },
  methods: {
    setLocale(locale) {
      this.nevMylng = locale;

      localStorage.nevMylng = locale;

      this.$i18n.locale = localStorage.nevMylng;
      this.$router.push({
        params: { lang: localStorage.nevMylng },
      });

      this.isActive = !this.isActive;

      var nwLg = this.nevMylng;
      // console.log(nwLg + "nwLg");
      this.$store.commit("newCurrentLang", "eng");
      // window.location.reload();
    },
    //   onSearchTextChange = (event) => {
    //   this.setState({
    //     searchText: event.target.value,
    //   })
    // }
    logoClick(event) {
      var nwLg = this.nevMylng;
      this.$i18n.locale = nwLg;
      this.yes = event.target;
      // console.log(event.target.href + "___this.yes");
      // let reloadVal = this.reloadPage;
      // this.$store.commit("reloadPage", 1);
      // if (this.reloadPage == 1 && this.showfooter == false) {
      // console.log(this.reloadPage + "this.reloadPage sec");
      // this.$store.commit("reloadPage", 5);
      // console.log(this.reloadPage + "this.reloadPage after");
      // window.location.reload();

      // console.log(this.reloadPage + "__this.reloadPage br");
      // console.log(this.$route.path + "__this.$route.path");
      // console.log(this.$route.to.path + "__this.$route.to.path");

      if (this.reloadPage == event.target.href) {
        window.location.reload();
      } else {
      }
      // this.$router.push({ path: this.reloadPage });
      // }
    },

    langOpen() {
      this.isActive = !this.isActive;
    },
    mobileMenu() {
      document.body.classList.add("mobil-open");
    },
    stopMenu(event) {
      event.stopPropagation();
    },
    closeMenu() {
      document.body.classList.remove("mobil-open");
    },
    mobileDropMenu() {
      this.isShow = !this.isShow;
      this.mobileDrop = !this.mobileDrop;
    },
  },
};
</script>
<style scoped>
.header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #fff;
  z-index: 9999999;
}
.sub-menu {
  display: block !important;
  visibility: hidden;
  opacity: 0;
  transition: all 0.25s ease-in-out;
  transform: translateY(10px);
}
.has-sub:hover .sub-menu {
  visibility: visible;
  opacity: 1;
  transform: translateY(0px);
}
.has-mob-sub .sub-mob-menu {
  display: block !important;
  max-height: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.25s ease-in-out;
}
.has-mob-sub.opnd .sub-mob-menu {
  max-height: 600px;
  opacity: 1;
  visibility: visible;
}
.has-mob-sub.opnd .mobil-down {
  background-color: #333;
}
.has-mob-sub.opnd .mobil-down::before {
  background-position: 0 100%;
}
.language ul li.az a.az {
  display: none;
}
.language ul li.en a.en {
  display: none;
}
.hdr_back {
  position: absolute;
  right: 0;
}
.header-right .top-menu > ul > li > a:hover,
.header-right .top-menu > ul > li > a.active {
  color: #f04b23;
}
.header-right .top-menu > ul > li > a:hover:before,
.header-right .top-menu > ul > li > a.active:before {
  opacity: 1;
  top: 0;
}
.nav-item a {
  cursor: pointer;
}
</style>
