
<template>
  <div class="page">
    <div class="xsayt">
      <div class="centered-a hdr_center">
        <div class="hdr_back"></div>
      </div>
      <app-header> </app-header>
      <app-main> </app-main>
      <app-footer v-if="showfooter"> </app-footer>
    </div>
  </div>
</template>
<script>
import Header from "@/components/shared/Header.vue";
import Main from "@/components/main/Main.vue";
import Footer from "@/components/shared/Footer.vue";
export default {
  name: "MainPagesView",
  components: {
    appHeader: Header,
    appMain: Main,
    appFooter: Footer,
    // appFooter: () =>
    //   import(/* webpackChunkName: "footer" */ "@/components/shared/Footer.vue"),
  },
  computed: {
    showfooter() {
      return this.$store.getters.getFooterVal;
    },
  },
};
</script>
  
<style scoped>
@import "../assets/css/style.css?v4";
</style>
<style>
@import "../assets/css/responsive.css";
</style>

<style scoped>
.xsayt {
  padding: 92px 0 0 0;
}
.hdr_center {
  position: relative;
}
@media screen and (max-width: 1140px) {
  .xsayt {
    padding: 80px 0 0 0;
  }
}
@media screen and (max-width: 700px) {
  .xsayt {
    padding: 64px 0 0 0;
  }
}
</style>
  